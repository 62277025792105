import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import IDCRaindropData from '../Data/IDCRaindropData'
// import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppIDCRaindropWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0% 8% 4% 8%;
  .personalHeading {
    padding: 2% 0% 2% 0%;
    font-size: 2rem;
  }
  .PersonaliconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .ParaSize {
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 42px;
  }
  .ParaSizeQuote {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 42px;
    margin-top: 34px;
    font-style: italic;
    font-weight: bolder;
    color: #047cc2;
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
`

export const AppIDCRaindrop = () => {
  return (
    <AppIDCRaindropWapper>
      {IDCRaindropData.map((data, index) => {
        return (
          <div key={index}>
            <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="personalHeading" />
            {/* <ReUsePtagwithAnchorTag
              ParaLeft={data.ParaOneBefor}
              anchorPara={data.ParaOneAnchor}
              ParaRight={data.ParaOneAfter}
              href={data.AnchorLink}
              target="_blank"
              rel="noopener noreferrer"
              anchorStyle="achorPara"
              paraStyle="SupplyChainHeadingTwo"
            /> */}
            <ReUsePtag para={data.UpperParaOne} paraStyle="ParaSize" />
            <ReUsePtag para={data.UpperParaTwo} paraStyle="ParaSize" />
            <ReUsePtag para={data.LowerParaOne} paraStyle="ParaSize" />
            <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="personalHeading" />
            <ReUsePtag para={data.ParaPonitOne} Icon={data.icon} IconStyle="PersonaliconColor" paraStyle="ParaSize" />
            <ReUsePtag para={data.ParaPonitTwo} Icon={data.icon} IconStyle="PersonaliconColor" paraStyle="ParaSize" />
            <ReUsePtag para={data.ParaPonitThree} Icon={data.icon} IconStyle="PersonaliconColor" paraStyle="ParaSize" />
            <ReUsePtag para={data.LowerParaTwo} paraStyle="ParaSizeParaSizeQuote" />
            <ReUsePtag para={data.UpperParaThree} paraStyle="ParaSizeQuote" />
            <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="personalHeading" />
            <ReUsePtag para={data.UpperParaFour} paraStyle="ParaSize" />
            <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="personalHeading" />
            <ReUsePtag para={data.UpperParaFive} paraStyle="ParaSize" />
          </div>
        )
      })}
    </AppIDCRaindropWapper>
  )
}
