import React from 'react'
import styled from 'styled-components'
// import { ReUseHOneTag } from './ReUseHOneTag'
import { ReuseImgTag } from './ReuseImgTag'
import IDCBannerData from '../Data/IDCBannerData.json'

const IDCBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  /* padding: 1%; */
  /* padding-top: 100px; */
  .IDCBannerImageStyle {
    display: flex;
    justify-content: left;
    padding-top: 3%;
    @media (max-width: 1321px) {
      padding: 10%;
    }
  }
  .IDCBannerImage {
    height: auto;
    width: 100vw;
    @media (max-width: 1321px) {
      width: 100%;
    }
  }
  .IDCBannerHeading {
    font-size: 2rem;
  }
  .HeadingSection {
    padding: 1% 7% 0% 7%;
  }
  .ControllerSection {
    margin: auto;
    @media (max-width: 1321px) {
      flex-wrap: wrap;
    }
  }
`

export const AppIDCBanner = () => {
  return (
    <IDCBannerWapper>
      {IDCBannerData.map((data, index) => {
        return (
          <div key={index}>
            <div className="ControllerSection">
              <ReuseImgTag ImagePath={data.ImagePath} ImageStyle="IDCBannerImage" />
              {/* <div className="HeadingSection">
                <ReUseHOneTag Heading={data.Title} HeadingStyle="IDCBannerHeading" />
              </div> */}
            </div>
          </div>
        )
      })}
    </IDCBannerWapper>
  )
}
