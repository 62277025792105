import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    HeadingOne: 'Santa Clara, March 8, 2023',
    UpperParaOne:
      'Raindrop, an industry-disruptive full-suite Procure-to-Pay (P2P) spend management platform was announced as a “Major Player” in the IDC MarketScape: Worldwide SaaS and Cloud-enabled Procurement Applications 2023 Vendor Assessment.',
    UpperParaTwo:
      'Raindrop, built in the cloud with powerful AI and machine learning, brings two overriding principles and value propositions they’ve rapidly become known for – ease-of-use, and rapid time to value.  Today, Raindrop is a Major Player seeking to change the narrative with their single pane of glass P2P modular platform, enabling clients to be up and running and already using their system quickly, in a matter of weeks, not months.',
    LowerParaOne:
      'Raindrop, founded by deeply experienced procurement and operations practitioners in 2019, is designed to solve modern digital spend management challenges, from procurement planning, supplier onboarding and risk management, running RFx sourcing events in the cloud, through to full contract lifecycle management, and a complete Payables solution.  Based on IDC analysis and customer feedback, it’s a proud moment for Team Raindrop to be recognized and so rapidly ascend into the Major Players category of this 2023 procurement IDC MarketScape.',
    HeadingTwo: 'The IDC MarketScape highlights Raindrop’s strengths, including:',
    ParaPonitOne:
      'Raindrop has shown that it means business with the two value propositions that it promotes — time to value and ease of use. Client references fully endorse both of these attributes, reporting relatively painless and quick implementations that had them operating the platform in weeks. Clients enjoy the clean, simple user interface and report that it is intuitive and easy to understand.',
    ParaPonitTwo:
      'Client references felt they were extremely well cared for by Raindrop. One even paid Raindrop the ultimate compliment, saying that it is "not a software company, but a services company." Clients have direct input and support from senior levels of Raindrop management.',
    ParaPonitThree:
      'Simple, intuitive procedures to create reports provide excellent utility for clients, which reported the intuitive process of developing custom reports resulted in them utilizing the reporting capability much more often with Raindrop than with a previous application.',
    icon: <FaArrowCircleRight />,
    LowerParaTwo:
      '“The P2P market, from mid-market to global enterprises, have been asking for digital solutions which are easy to consume, and rapid to gain value from.  The days of long and expensive deployments and training are officially over.  With Raindrop’s full-suite solution, customers require tying key systems together to provide transparency into the funds leaving their company, and Raindrop is uniquely positioned to rapidly scale to fit their modular needs, so you can grow with Raindrop over time while managing your external supplier spend, quickly and easily,” advises Ward Karson, Chief Operating Officer, Raindrop. He further added, “We are grateful to our customers and IDC for recognizing our value proposition and marking us as a Major Player in the IDC MarketScape this year”.',
    UpperParaThree:
      'According to Patrick Reymann, Director, IDC Research Director, Procurement and Enterprise Applications, “Raindrop’s leadership team of experienced procurement professionals have built a truly disruptive enterprise spend management solution with the end user in mind. Raindrop’s impressive speed to value attainment, ease of use, and intuitive and clean user interface are true differentiators that deserve strong consideration.”',
    HeadingThree: 'About IDC MarketScape',
    UpperParaFour:
      'IDC MarketScape vendor assessment model is designed to provide an overview of the competitive fitness of ICT (information and communications technology) suppliers in the market. The research methodology is based on both qualitative and quantitative criteria that results in a single graphical illustration of each vendor’s position within a given market. IDC MarketScape provides a clear framework in which the product and service offerings, capabilities and strategies, and current and future market success factors of IT and telecommunications vendors can be meaningfully compared. The framework also provides technology buyers with a 360-degree assessment of the strengths and weaknesses of current and prospective vendors.',
    HeadingFour: 'About Raindrop',
    UpperParaFive:
      'Raindrop’s intuitive AI-based Procure-to-Pay (P2P) Spend Management solution provides visibility and unparalleled capabilities in controlling how and who spends money at your company. Raindrop digitizes your Procurement processes and is created to focus on delivering efficiencies, be they cost savings, cost avoidance, or other programmatic benefits. Raindrop seamlessly and readily integrates with all major ERP systems, and other central data sources to create a unified and normalized view of company spend. Raindrop’s easy-to-use interface enables users to address data as desired, further enabling you to create and share reports with several layers of analytics that are very easily accessible.',
  },
]
